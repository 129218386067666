<template>
    <div class="h-full">
        <div v-if="!state.showMailConfirmationDialog && !state.showTwoFactor" class="h-full flex gap-20 flex-col justify-between">
            <div>
                <div>
                    <h1 class="text-2xl mt-8 mb-2">Anmelden</h1>
                    <ElementsForm path="login" @submit="onSubmit" v-slot="{ validation }">
                        <ElementsAlert type="warning" :active="state.error.visible">{{ state.error.message }}</ElementsAlert>
                        <ElementsInput :validation="validation" type="email" path="user.email" label="E-Mail" placeholder="E-Mail" v-model="loginForm.identifier" required />
                        <ElementsInput :validation="validation" type="password" path="user.password" placeholder="Passwort" v-model="loginForm.password" required>
                            <template #label>
                                <div class="flex justify-between mb-2">
                                    <label>
                                        Passwort
                                        <span class="inline-block text-gray-400">*</span>
                                    </label>
                                    <NuxtLink @click="$emit('resetPasswordClicked')" class="block text-center text-primary cursor-pointer hover:underline">Passwort vergessen?</NuxtLink>
                                </div>
                            </template>
                        </ElementsInput>
                        <ElementsButton type="submit" :loading="state.loading" showAs="full">Anmelden</ElementsButton>
                    </ElementsForm>
                </div>
                <div>
                    <h1 class="text-2xl mt-8 mb-2">Noch kein Konto?</h1>
                    <p class="mb-6">
                        Mit dem persönlichen Login haben sie die Möglichkeit, Ihre gewünschte Regionalmarke(n) auszuwählen.
                    </p>
                    <ElementsButton @click="state.registerLoading = true; $emit('registerButtonClicked'); state.registerLoading = false" type="button" :loading="state.registerLoading" showAs="full" color="empty">Jetzt Registrieren</ElementsButton>
                </div>
            </div>
            <div class="w-1/2 mx-auto mb-8"><ThemeFooter /></div>
        </div>
        <div v-else-if="state.showTwoFactor">
            <div>
                <h1 class="text-2xl mt-8 mb-2">2-Faktor-Authentifizierung</h1>
                <p>Geben Sie den Sicherheitscode aus Ihrer 2-Faktor-Authenticator-App ein, um die Anmeldung abzuschliessen.</p>
                <ElementsForm path="twofactor" @submit="onSubmit" v-slot="{ validation }">
                    <ElementsAlert type="warning" :active="state.error.visible">{{ state.error.message }}</ElementsAlert>
                    <ElementsAuthenticatorCode v-model="loginForm.code" :validation="validation" @submit="onSubmit" class="my-12 md:my-20" />
                    <ElementsButton type="submit" :loading="state.loading" showAs="full">Anmelden</ElementsButton>
                </ElementsForm>
            </div>
        </div>
        <LazyHomePartialsMailConfirmation :email="loginForm.identifier" v-else />
    </div>
</template>
<script setup>
const emit = defineEmits(['registerButtonClicked', 'resetPasswordClicked', 'loggedIn'])

const { setToken, fetchUser } = useStrapiAuth()
const client = useStrapiClient()
const login = async (data) => {
    setToken(null);
    const { jwt, refresh } = await client("/auth/local", { method: "POST", body: data });
    setToken(jwt);
    const user2 = await fetchUser();
    return {
        user: user2,
        jwt,
        refresh
    };
}
const route = useRoute()
const router = useRouter()

const loginForm = reactive({
    identifier: null,
    password: '',
    code: '',
})

const state = reactive({
    loading: false,
    showMailConfirmationDialog: false,
    showTwoFactor: false,
    error: {
        visible: false,
        message: null
    },
    registerLoading: false,
})

const refreshToken = useRefreshToken()

const onSubmit = async () => {
    try {
        state.loading = true;
        let result = await login(loginForm)
        console.log(result)
        refreshToken.value = result?.refresh
        emit('loggedIn')
        setTimeout(() => window.location.href = route.query.redirect ? route.query.redirect : '/', 125)
    } catch (e) {
        console.log(e)
        if(e?.error?.message == '2FA required'){
            //Login correct, but 2fa needed
            state.error.visible = false
            state.showTwoFactor = true
        }
        else if(e?.error?.message == 'Your account email is not confirmed'){
            state.showMailConfirmationDialog = true
        }
        else {
            if(e?.error?.message == 'Your account has been blocked by an administrator')
                state.error.message = 'Ihr Konto wurde gesperrt. Kontaktieren Sie uns um mehr zu erfahren.';
            else if(e?.error?.message == '2FA invalid' || e?.error?.message == 'Der Code aus der 2-Faktor-Authentifizierung ist ungültig.')
                state.error.message = 'Der Code aus der 2-Faktor-Authentifizierung ist ungültig.'
            else
                state.error.message = 'E-Mail oder Passwort ungültig. ';
            state.error.visible = true;
        }
        state.loading = false;
    }
}
</script>